import React from 'react'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'
import Footer from '../components/Footer'

const CookiesPolicyPage = () => (
  <Layout path="/security" title="Cookies Policy - Brentiv - Rental Software">
    <div className="legal-page cookies-policy-page">
      <SmallHeader title="Cookies Policy" />
      <div className="row-spaced legal-page-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10">
              <div className="container-padded">
                <p>
                  Brentiv (“us”, “we”, or “our”) uses cookies on the brentiv.com
                  website (the “Service”) and its sub-domains. By using the
                  Service, you consent to the use of cookies. Our Cookies Policy
                  explains what cookies are, how we use cookies, how
                  third-parties we may partner with may use cookies on the
                  Service, your choices regarding cookies and further
                  information about cookies.
                </p>
                <h2 className="title">What are cookies</h2>
                <p>
                  Cookies are small pieces of text sent by your web browser by a
                  website you visit. A cookie file is stored in your web browser
                  and allows the Service or a third-party to recognize you and
                  make your next visit easier and the Service more useful to
                  you. Cookies can be “persistent” or “session” cookies.
                  Persistent cookies remain on your personal computer or mobile
                  device when you go offline, while session cookies are deleted
                  as soon as you close your web browser.
                </p>
                <h2 className="title">
                  How Brentiv uses cookies on our website
                </h2>
                <p>
                  When you use and access the Service, we may place a number of
                  cookies files in your web browser. We use cookies for the
                  following purposes: preferences, statistics, marketing.
                </p>
                <h2 className="title">How to control cookies?</h2>
                <p>
                  You can control and manage cookies in various ways. Please
                  keep in mind that removing or blocking cookies can negatively
                  impact your user experience and parts of our website may no
                  longer be fully accessible.
                </p>
                <p>
                  Most browsers automatically accept cookies, but you can choose
                  whether or not to accept cookies through your browser
                  controls, often found in your browser’s “Tools” or
                  “Preferences” menu. For more information on how to modify your
                  browser settings or how to block, manage or filter cookies can
                  be found in your browser’s help file or through such sites as:{' '}
                  <a
                    href="www.allaboutcookies.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>
                  Many of the third party advertising and other tracking
                  services listed above offer you the opportunity to opt out of
                  their tracking systems. You can read more about the
                  information they collect and how to opt out through the
                  privacy policy links listed above.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </Layout>
)

export default CookiesPolicyPage
